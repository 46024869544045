<template>
    <div class="immersion-widget">
        <div class="immersion-frame">
            <div class="immersion-frame__content">
                <VJoyIcon size="xxsmall" name="warning" color="secondary" />
                <div class="immersion-frame__text">
                    You're seeing this page as if you were {{ userInImmersion }}. The main menu is still yours, though.
                    <VJoyLink :href="currentUrlWithoutImmersion">Exit from immersion</VJoyLink>
                </div>
            </div>
        </div>
        <div class="immersion-frame sudosu-side" style="left: 0" />
        <div class="immersion-frame sudosu-side" style="right: 0" />
        <div class="immersion-frame" style="left: 0; top: 0; height: 5px; width: 100%" />
    </div>
</template>

<script setup lang="ts">
    import {onMounted, onUnmounted, ref, computed} from 'vue';
    import {VJoyIcon, VJoyLink} from '@maltjoy/core-vue';
    import {IMMERSION_QUERY_PARAM, useRoute, useRouter} from '#imports';

    const userInImmersion = ref<string | undefined | null>();
    let observer: MutationObserver;

    const route = useRoute();
    const router = useRouter();
    const currentUrlWithoutImmersion = computed(() => {
        const {[IMMERSION_QUERY_PARAM]: _, ...queryParams} = route.query;
        return router.resolve({path: route.path, query: queryParams}).href;
    });

    onMounted(() => {
        observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-immersion-user') {
                    userInImmersion.value = document.body.getAttribute('data-immersion-user');
                }
            });
        });

        observer.observe(document.body, {
            attributes: true,
        });
    });

    onUnmounted(() => {
        if (observer) {
            observer.disconnect();
        }
    });
</script>

<style lang="scss">
    .immersion-widget {
        display: none;
    }
    body.immersion-mode .immersion-widget {
        display: block;
    }

    body.immersion-mode button:not(.allow-immersion, [data-allow-immersion]) {
        pointer-events: none;
        background-color: var(--joy-color-neutral-30) !important;
        border-color: var(--joy-color-neutral-40) !important;
    }

    body.immersion-mode {
        joy-dropzone[data-allow-immersion] {
            button {
                pointer-events: auto;
                color: white !important;
                background-color: var(--joy-color-secondary-30) !important;
                border-color: var(--joy-color-secondary-40) !important;
            }
        }

        .joy-tabs[data-allow-immersion] {
            button {
                pointer-events: auto;
                background-color: transparent !important;
                color: inherit !important;
            }
        }

        .joy-collapse-item[data-allow-immersion] {
            button {
                pointer-events: auto;
                background-color: transparent !important;
                color: inherit !important;
            }
        }
    }

    body.immersion-mode :is(div, nav).allow-immersion,
    body.immersion-mode :is(div, nav)[data-allow-immersion] {
        & button {
            pointer-events: auto;
            color: white !important;
            background-color: var(--joy-color-secondary-30) !important;
            border-color: var(--joy-color-secondary-40) !important;

            &.joy-filter-bar-button {
                color: black !important;
                background-color: #fff !important;
                border-color: var(--joy-color-neutral-30) !important;

                &--active {
                    background-color: var(--joy-color-secondary-10) !important;
                    border-color: var(--joy-color-secondary-30) !important;
                }
            }
        }
    }

    .immersion-frame {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1100;
        width: 100%;
        background: #1fde91;
        color: #343a5e;
        font-size: var(--joy-font-size-primary-200);

        &__text {
            padding: 8px 5px;
        }

        &__content {
            line-height: 12px;
            display: flex;
            align-items: center;
            margin-left: var(--joy-core-spacing-6);
        }
    }

    .sudosu-side {
        top: 0;
        height: 100%;
        width: 5px;
    }
</style>
